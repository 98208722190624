import { createContext, useContext, useState, useEffect } from 'react';

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

import { getProfil } from '../api-service-common';

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export default function UserContextProvider({ children }) {
  const auth = getAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser);
      setLoadingData(false);
    });
    return () => unsubscribe(); // As cleanup, to avoid eventual memory leak
  }, [auth]);

  // This function is used to login using email and password by calling firebase backend
  const signin = async ({ email, password }) => {
    try {
      await setPersistence(auth, browserSessionPersistence);
      console.log(email);
      console.log(password);
      await signInWithEmailAndPassword(auth, email, password);
      const profil = await getProfil(auth.currentUser);
      localStorage.setItem('profil', JSON.stringify(profil));
    } catch (error) {
      console.error(error);
    }
  };

  // This function is used to logout by calling firebase backend using auth object
  const signOut = async () => {
    try {
      await signOutFirebase(auth);
      localStorage.removeItem('profil');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider value={{ currentUser, signin, signOut }}>
      {loadingData ? null : children}
    </UserContext.Provider>
  );
}
