import * as Yup from 'yup';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { $NB_MAX_TENANTS, $NB_MAX_ANNEXES, $NB_MAX_FLOOR } from './defaultValue';

const validationSchema = (typeHouse) => {
  console.log('TODO :Gerer les numeros de telephones internationaux');
  const commonSchema = Yup.object()
    .shape({
      pricing: Yup.object(),
      tenants: Yup.array()
        .of(
          Yup.object().shape({
            civility: Yup.string().oneOf(['Mme', 'M.']).required(),
            name: Yup.string()
              .notRequired()
              .max(40, '40 caractères maximum')
              .nullable()
              .transform((value) => (!!value ? value : null)),
            surname: Yup.string().required('Champ requis').max(40, '40 caractères maximum').nullable(),
            email: Yup.string()
              .email('Format non-valide')
              .nullable()
              .transform((value) => (!!value ? value : null)),
            phone: Yup.string()
              .test(
                'is-french-phone',
                (param) => (param.value ? 'N° de téléphone incorrect' : 'Champ requis'),
                (value) => /^(?:\+[0-9]{1,3}|0)[0-9]{6,14}$/.test(value),
              )
              .nullable(),
          }),
        )
        .min(1, 'Minimun locataires : 1')
        .max($NB_MAX_TENANTS, 'Maximun locataires : ' + $NB_MAX_TENANTS),
      manage: Yup.object().shape({
        dateEDL: Yup.date().test(
          'dateEDL',
          () => 'Champ requis',
          function (value) {
            if (dayjs(value, true).isValid()) {
              if (dayjs().add(1, 'day').isBefore(dayjs(value), 'day')) {
                return true;
              }
              return this.createError({
                message: 'Indiquer une date ultérieure',
              });
            } else {
              return this.createError({ message: 'Format date EDL invalide' });
            }
          },
        ),
        typeEDL: Yup.string().oneOf(['entrant', 'sortant']).required(),
        video: Yup.string().oneOf(['oui', 'non']).required(),
      }),
      possession: Yup.object().shape({
        address: Yup.object().shape({
          street: Yup.string().required('Champ requis').nullable(),
          complement: Yup.string()
            .notRequired()
            .max(40, '40 caractères maximum')
            .nullable()
            .transform((value) => (!!value ? value : null)),
          zipCode: Yup.string()
            .required('Champ requis')
            .matches(/^[0-9]+$/, 'Seulement des chiffres')
            .min(5, 'Format sur 5 chiffres')
            .max(5, 'Format sur 5 chiffres')
            .nullable(),
          locality: Yup.string().max(40, '40 caractères maximum').required('Champ requis').nullable(),
          region: Yup.string().oneOf(['IDF']).required(),
          geopoint: Yup.object().required(),
        }),
        nameOwner: Yup.string()
          .required('Champ requis')
          .max(40, '40 caractères maximum')
          .nullable()
          .transform((value) => (!!value ? value : null)),
        typeHouse: Yup.string().oneOf(['appartement', 'maison', 'parking']).required(),
        typeRent: Yup.string().oneOf(['nu', 'meublé']).required(),
        nbRoom: Yup.string().oneOf(['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8']).required(),
        area: Yup.number()
          .typeError('Format numérique')
          .required('Champ requis')
          .min(9, '9 à 1000m²')
          .max(1000, '9 à 1000m²')
          .test('is-valid-number', 'Nombre invalide', (value) => {
            return /^\d+(\.\d{1,2})?$/.test(value);
          }),
        numFloor: Yup.number()
          .typeError('Format numérique')
          .required('Champ requis')
          .min(0, 'min 0')
          .max($NB_MAX_FLOOR, 'Maximun ' + $NB_MAX_FLOOR),
        annexes: Yup.object().shape({
          nbCaves: Yup.number()
            .typeError('Format numérique')
            .required('Champ requis')
            .min(0, 'min 0')
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
          caves: Yup.array()
            .of(
              Yup.object().shape({
                num: Yup.string()
                  .notRequired()
                  .max(20, '20 caractères maximum')
                  .nullable()
                  .transform((value) => (!!value ? value : null)),
              }),
            )
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
          nbParkings: Yup.number()
            .typeError('Format numérique')
            .required('Champ requis')
            .min(0, 'min 0')
            .max($NB_MAX_ANNEXES, 'max 3'),
          parkings: Yup.array()
            .of(
              Yup.object().shape({
                num: Yup.string()
                  .notRequired()
                  .max(20, '20 caractères maximum')
                  .nullable()
                  .transform((value) => (!!value ? value : null)),
              }),
            )
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
          nbBalcony: Yup.number()
            .typeError('Format numérique')
            .required('Champ requis')
            .min(0, 'min 0')
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
          nbGarden: Yup.number()
            .typeError('Format numérique')
            .required('Champ requis')
            .min(0, 'min 0')
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
          nbOther: Yup.number()
            .typeError('Format numérique')
            .required('Champ requis')
            .min(0, 'min 0')
            .max($NB_MAX_ANNEXES, 'Maximun ' + $NB_MAX_ANNEXES),
        }),
      }),
      grades: Yup.string()
        .notRequired()
        .max(300, '300 caractères maximum')
        .nullable()
        .transform((value) => (!!value ? value : null)),
    })
    .noUnknown(true)
    .strict(true);

  if (typeHouse === 'parking') {
    delete commonSchema.fields.manage.fields.video;
    delete commonSchema.fields.possession.fields.annexes;
    delete commonSchema.fields.possession.fields.area;
    delete commonSchema.fields.possession.fields.nbRoom;
    delete commonSchema.fields.possession.fields.typeRent;
  }
  return commonSchema;
};

export { validationSchema };
