import React, { useState, useEffect } from 'react';
import { useAppContext } from './contexts-api/AppContextProvider';
import { useUserContext } from './contexts-api/AuthProvider';
import { menuItems } from './navigation/data/navigation-menu';
import Aside from './componentsOLD/Aside';
import PropTypes from 'prop-types';

// Material ui components import
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ToolbarSky from './ToolbarSky';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

import { drawerWidth, INITIAL_DISPLAY_STATE, RESET_DISPLAY, APP_VIEWS } from './app-constants';
// custom components used to render a specific view in relation of our business logic
import MyEDLs from './pages/myEDLs';
import NewEDL from './pages/newEDL';
// TODO: import the invoice page here
import Profil from './pages/profil';
import Test from './pages/test';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('fr');
dayjs.tz.setDefault('Europe/Paris');

function App(props) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { signOut } = useUserContext();
  const { profil } = useAppContext();
  const company = profil.company;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [display, setDisplay] = useState(INITIAL_DISPLAY_STATE);
  const [, setMessageInfos] = useState([0, 'success', '']);

  delete menuItems.app.invoice;
  delete menuItems.app.admin;
  //delete menuItems.app.test;

  if (company.type === 'admin' || company.type === 'provider') {
    delete menuItems.app.newEDL;
    if (company.type === 'admin') {
      delete menuItems.app.invoice;
    }
  }

  const [edlToModify, setEdlToModify] = useState(null);

  useEffect(() => {
    if (edlToModify) {
      handlePageChange('Créer un EDL');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edlToModify]);

  const handlePageChange = (page) => {
    switch (page) {
      case APP_VIEWS.myedls:
        setDisplay({ ...RESET_DISPLAY, myedls: 'flex' });
        break;
      case APP_VIEWS.newedl:
        setDisplay({ ...RESET_DISPLAY, newedl: 'flex' });
        break;
      case APP_VIEWS.profil:
        setDisplay({ ...RESET_DISPLAY, profil: 'flex' });
        break;
      case APP_VIEWS.invoice:
        setDisplay({ ...RESET_DISPLAY, invoice: 'flex' });
        break;
      case APP_VIEWS.test:
        setDisplay({ ...RESET_DISPLAY, test: 'flex' });
        break;
      default:
        return null;
    }
    // Used to close the drawer on mobile when a page is selected before the re-rendering
    if (isXs) toggleDrawer();
  };

  // Used to open and close the drawer on mobile
  const toggleDrawer = () => {
    setMobileOpen((mobileOpen) => !mobileOpen);
  };

  const handleUserLogout = () => {
    signOut();
  };

  const container = window?.document.body;

  return (
    <>
      <Grid container direction="column" flexGrow={1}>
        <Grid>
          <ToolbarSky drawerWidth={drawerWidth} handlelickMenu={toggleDrawer} profil={profil} />
        </Grid>
        <Grid>
          {/* Desktop Layout */}
          <Drawer
            variant="permanent"
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
              },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#1791D1',
                color: 'white',
                borderRight: '0px',
              },
            }}
            open
          >
            {Aside({ handlePageChange, handleUserLogout, menuItems })}
          </Drawer>
          {/* Desktop Layout */}
          {/* Mobile Layout */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {
                xs: 'block',
                sm: 'none',
              },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#1791D1',
                color: 'white',
                borderRight: '0px',
              },
            }}
          >
            {Aside({ handlePageChange, handleUserLogout, menuItems })}
          </Drawer>
          {/* Mobile Layout */}
          {/* The entire app right part is handled by the component below */}
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexGrow: 1,
              width: {
                sm: 'calc(100% - ' + drawerWidth + 'px)',
              },
              p: 3,
              mt: 4,

              ml: { sm: drawerWidth + 'px' },
            }}
            flexDirection="column"
          >
            <Box
              sx={{
                display: display.myedls,
                flexGrow: 1,
              }}
            >
              <MyEDLs setEdlToModify={setEdlToModify} display={display.myedls} setMessageInfos={setMessageInfos} />
            </Box>
            <Box
              sx={{
                display: display.newedl,
                flexGrow: 1,
              }}
            >
              <NewEDL edlToModify={edlToModify} setMessageInfos={setMessageInfos} handlePageChange={handlePageChange} />
            </Box>
            {/* TODO: Add the logic responsible to display the invoice view here, using the same pattern as above */}
            <Box
              sx={{
                display: display.profil,
                flexGrow: 1,
              }}
            >
              <Profil />
            </Box>

            {/*<Box sx={{ display: display.test }}>
              <Test />
            </Box>*/}
          </Box>
          {/* The entire app righ part is managed by the component above  */}
        </Grid>
      </Grid>
    </>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
