import axios from 'axios';
import { collectionGroup, collection, getDocs, doc, getDoc, query, where, limit } from 'firebase/firestore';

import { dbFirebase } from './config';
import { getAuth } from 'firebase/auth';

const apiUrl = `${process.env.REACT_APP_API_URL}`;

export async function getToken() {
  const token = await getAuth().currentUser.getIdToken(true);
  return token;
}

export async function getDistance(origin, destination) {
  const token = await getToken();
  const url = `${apiUrl}/distance`;
  const res = await axios.post(url, { origin, destination, token });
  return res.data.distance;
}

export async function getListSociety() {
  const queryListSociety = query(collection(dbFirebase, '/companies'), where('type', '==', 'customer'));
  const querySnapshot = await getDocs(queryListSociety);
  const listSociety = [];
  querySnapshot.forEach((doc) => {
    listSociety.push(doc.data().name);
  });
  return listSociety;
}

export async function getProfil(currentUser) {
  console.log(currentUser);
  const queryUser = query(collectionGroup(dbFirebase, 'users'), where('uid', '==', currentUser.uid), limit(1));
  const user = await getDocs(queryUser);
  if (user.empty) {
    console.log('no existing user');
    return;
  }

  console.log(user);

  const nameCompany = user.docs[0].data().company;

  const queryCompagny = query(doc(dbFirebase, '/companies/' + nameCompany));
  const company = await getDoc(queryCompagny);
  if (company.empty) {
    console.log('no existing profil for user');
    return;
  }

  let priceList = null;

  if (company.data().type !== 'admin') {
    const queryPriceList = query(
      collection(dbFirebase, '/companies/' + nameCompany + '/priceList'),
      where('status', '==', 'active'),
      limit(1),
    );
    priceList = await getDocs(queryPriceList);

    if (priceList.empty) {
      console.log('no existing user for getprice');
      return;
    }
  }

  const infosProfil = Object.assign(
    { company: company.data() },
    {
      priceList: priceList?.docs[0]?.data(),
      user: user?.docs[0]?.data(),
    },
  );

  return infosProfil;
}

export function calcPrice(datasEdl, distanceAdrCompanyToAdrEdl, pricing) {
  const typeHouse = datasEdl.possession.typeHouse;
  const superficie = datasEdl.possession.area;
  const nbRoom = datasEdl.possession.nbRoom;
  const maxSuperficie = pricing[typeHouse][nbRoom].maxSuperficie;

  const tempPricing = {};
  tempPricing['version'] = pricing.version;
  tempPricing['typeHouse'] = typeHouse;
  tempPricing['nbRoom'] = nbRoom;
  tempPricing['priceByAnnexe'] = pricing.annexe;
  tempPricing['nbCaves'] = datasEdl.possession.annexes.nbCaves;
  tempPricing['nbParkings'] = datasEdl.possession.annexes.nbParkings;
  tempPricing['nbBalcony'] = datasEdl.possession.annexes.nbBalcony;
  tempPricing['nbGarden'] = datasEdl.possession.annexes.nbGarden;
  tempPricing['nbOther'] = datasEdl.possession.annexes.nbOther;
  tempPricing['superficie'] = superficie;
  tempPricing['maxSuperficie'] = maxSuperficie;
  tempPricing['supSuperficie'] = superficie - maxSuperficie < 0 ? 0 : (superficie - maxSuperficie).toFixed(2);
  tempPricing['priceBySupMeters'] = pricing[typeHouse][nbRoom].majoSuperficie;
  tempPricing['annulation'] = pricing.annulation;

  tempPricing['deplacement'] = {};
  const distanceKm = distanceAdrCompanyToAdrEdl / 1000;
  tempPricing['deplacement']['km'] = distanceAdrCompanyToAdrEdl / 1000;
  tempPricing['deplacement']['mode'] = pricing.deplacement.mode;
  tempPricing['deplacement']['baseTarif'] = pricing.deplacement.baseTarif;
  tempPricing['deplacement']['majoTarif'] = pricing.deplacement.majoTarif;
  tempPricing['deplacement']['maxBorne'] = pricing.deplacement.maxBorne;

  tempPricing['tarif'] = {};
  tempPricing['tarif']['base'] = pricing[typeHouse][nbRoom].baseTarif;

  tempPricing['tarif']['superficie'] =
    superficie > maxSuperficie ? pricing[typeHouse][nbRoom].majoSuperficie * tempPricing['supSuperficie'] : 0;

  if (typeHouse === 'parking') {
    tempPricing['tarif']['base'] = pricing.parking;
    tempPricing['tarif']['total'] = pricing.parking + tempPricing['tarif']['deplacement'];
  } else {
    tempPricing['tarif']['meuble'] = datasEdl.possession.typeRent === 'nu' ? 0 : pricing[typeHouse][nbRoom].majoMeuble;

    tempPricing['tarif']['caves'] = datasEdl.possession.annexes.nbCaves * pricing.annexe;
    tempPricing['tarif']['parkings'] = datasEdl.possession.annexes.nbParkings * pricing.annexe;
    tempPricing['tarif']['balcony'] = datasEdl.possession.annexes.nbBalcony * pricing.annexe;
    tempPricing['tarif']['garden'] = datasEdl.possession.annexes.nbGarden * pricing.annexe;
    tempPricing['tarif']['other'] = datasEdl.possession.annexes.nbOther * pricing.annexe;
    tempPricing['tarif']['video'] = datasEdl.manage.video === 'non' ? 0 : pricing[typeHouse][nbRoom].majoVideo;

    if (pricing.deplacement.mode === 'forfait') {
      tempPricing['tarif']['deplacement'] =
        pricing.deplacement.maxBorne - distanceKm < 0
          ? pricing.deplacement.baseTarif + pricing.deplacement.majoTarif
          : (tempPricing['tarif']['deplacement'] = pricing.deplacement.baseTarif);
    }
    tempPricing['tarif']['total'] =
      tempPricing['tarif']['base'] +
      tempPricing['tarif']['superficie'] +
      tempPricing['tarif']['deplacement'] +
      tempPricing['tarif']['meuble'] +
      tempPricing['tarif']['caves'] +
      tempPricing['tarif']['parkings'] +
      tempPricing['tarif']['balcony'] +
      tempPricing['tarif']['garden'] +
      tempPricing['tarif']['other'] +
      tempPricing['tarif']['video'];
  }

  return tempPricing;
}
